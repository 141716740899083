<template>
  <div class="c-address">
    <!-- 弹窗 -->
    <s-drawer
      v-if="modal"
      v-bind="drawerConfig"
      :visible.sync="isShow"
      append-to-body
      :no-header="hasHeader"
      immediately-render
      :close-on-click-modal="false"
      @close-from-icon="closeFromIcon"
    >
      <template slot="top">
        <slot name="top"></slot>
      </template>
      <SBusinessAddress
        ref="addressVue"
        v-bind="config"
        @add="add"
        @edit="edit"
        @supplement="supplement"
        @cancel="cancel"
        @error="error"
        @afterSelectedCountry="afterSelectedCountry"
      >
        <template slot="bottom">
          <slot name="bottom">
          </slot>
        </template>
      </SBusinessAddress>
    </s-drawer>
    <!-- 嵌入页面 无标题 -->
    <SBusinessAddress
      v-else
      ref="addressVue"
      v-bind="config"
      :has-header="false"
      @add="add"
      @edit="edit"
      @cancel="cancel"
      @supplement="supplement"
      @error="error"
      @afterSelectedCountry="afterSelectedCountry"
    >
      <template slot="bottom">
        <slot name="bottom">
        </slot>
      </template>
    </SBusinessAddress>
  </div>
</template>

<script>
import Vue from 'vue'
import 'public/src/pages/common/business'
import { SBusinessAddress } from '@shein/business-address'
import { isFunction } from '@shein/common-function'
import { daEventCenter } from 'public/src/services/eventCenter/index'
import {
  Drawer,
  Dialog,
  FieldDatePick,
  FieldBox,
  Button,
  Alert,
  Toast
} from '@shein/sui-mobile'
// 地址组件服务端渲染样式无法获取【 Vue.use 来给 vue-server-render 获取】
;[Drawer, Dialog, FieldDatePick, FieldBox, Button, Alert].forEach(item => {
  Vue.use(item)
})
daEventCenter.addSubscriber({ modulecode: '2-5' })

export default {
  name: 'AddressVue',
  components: {
    SBusinessAddress
  },
  props: {
    // 是否弹窗
    modal: {
      type: Boolean,
      default: true
    },
    // 显示隐藏
    show: {
      type: Boolean,
      default: false
    },
    // 引用页面
    pageName: {
      type: String,
      default: ''
    },
    extra: {
      type: Array,
      default() {
        return []
      }
    },
    pageType: {
      type: String,
      default: ''
    },
    // 控制国家省份是否可选
    disabled: {
      type: Array,
      default() {
        return []
      }
    },
    // 礼品卡页面
    giftcard: {
      type: Boolean,
      default: false
    },
    daEventId: {
      type: String,
      default: ''
    },
    beforeClose: {
      type: Function,
      default: null
    },
    activityFrom: {
      type: String,
      default: ''
    },
    // 弹窗的配置
    drawerConfig: {
      type: Object,
      default() {
        return {
          direction: 'rtl',
          type: 'full',
        }
      }
    },
    // 是否展示地址内部标题
    hasHeader: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      operateType: '',
      isShow: false,
      isLoading: false,
      check: null
    }
  },
  computed: {
    config() {
      return {
        pageName: this.pageName,
        extra: this.extra,
        pageType: this.pageType,
        disabled: this.disabled,
        giftcard: this.giftcard,
        daEventId: this.daEventId,
        activityFrom: this.activityFrom,
        hasHeader: this.hasHeader,
        drawerConfig: this.drawerConfig
      }
    }
  },
  watch: {
    show(value) {
      this.isShow = !!value
    }
  },
  mounted() {
    this.$nextTick(() => {
      this.setCheckRule()
    })
  },
  methods: {
    updateUI(type = '', addrData, cb) {
      this.operateType = type
      this.$nextTick(() => {
        this.$refs.addressVue?.updateUI?.(type, addrData, () => {
          this.setCheckRule()
          cb?.()
        })
      })
    },
    closeFromIcon(){
      this.$emit('addressCloseFromIcon')
      this.cancel()
    },
    add(data) {
      if(typeof window !== 'undefined' && !window.navigator.onLine) {
        Toast({
          content: window?.gbCommonInfo?.language?.SHEIN_KEY_PWA_31762
        })
        return
      }
      this.setCheckRule()
      this.$emit('add', data)
    },
    edit(data) {
      if(typeof window !== 'undefined' && !window.navigator.onLine) {
        Toast({
          content: window?.gbCommonInfo?.language?.SHEIN_KEY_PWA_31762
        })
        return
      }
      this.setCheckRule()
      this.$emit('edit', data)
    },
    supplement(data) {
      this.setCheckRule()
      this.$emit('supplement', data)
    },
    cancel() {
      if (isFunction(this.beforeClose)) {
        return this.beforeClose()
      }
      this.isShow = false
      this.$emit('cancel')
    },
    afterSelectedCountry(data) {
      this.$emit('afterSelectedCountry', data)
    },
    error(data) {
      this.$emit('error', data)
    },
    loading(isLoading = false) {
      this.$refs.addressVue.isLoading = isLoading
    },
    universalToast(content) {
      this.$refs.addressVue?.universalToast?.(content)
    },
    resultsTipsShow(flag, text, time = 1500) {
      this.$refs.addressVue?.resultsTipsShow?.(flag, text, time)
    },
    setAddressVueData() {
      this.check = this.$refs.addressVue?.check
      this.secondLanguage = this.$refs.addressVue?.secondLanguage || {}
    },
    setCheckRule() {
      this.check = this.$refs.addressVue?.check
    },
    scrollPassport() {
      this.$refs.addressVue?.scrollPassport?.()
    },
    handleSave() {
      this.$refs.addressVue?.handleSave?.()
    },
    errorHandler(params) {
      this.$refs.addressVue?.errorHandler?.(params)
    },
  }
}
</script>
<style lang="less">
// TODO 地址组件更新后删除
.S-field-box__date-pick-wrap {
  .S-field-box__date-pick-inner {
    position: absolute;
    top: 50%;
    left: 50%;
    right: auto;
    transform: translate(-50%, -50%);
  }
}
</style>
